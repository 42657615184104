<template>
  <div class="functionnality-cta">
    <div class="functionnality-cta__content">
      <h2 class="functionnality-cta__title">{{ title }}</h2>
      <p class="functionnality-cta__subtitle wysiwyg">{{ subtitle }}</p>
      <a :href="this.$beet.options.general.miriaLink" class="functionnality-cta__text">
        <Icon id="miria_star" />
        {{ linkLabel }}
      </a>
    </div>
    <img class="functionnality-cta__img" src="/images/home_functionnality_img.jpg">
  </div>
</template>

<script>
export default {
  name: 'FunctionalityCta',
  props: {
    title: { type: String, require: true },
    subtitle: { type: String, require: true },
    linkLabel: { type: String, require: true }
  },
  computed: {
    moduleUrl() {
      return this.$lang.current === 'fr' ? process.env.VUE_APP_MODULE_URL : `${process.env.VUE_APP_MODULE_URL}/en`
    }
  }
}
</script>
